import React from "react";

function Education() {
  return (
    <div id="sb">
      <p>
        <h3>Education</h3>
        <h5>Degrees</h5>
        <ul>
          <li>Florida International University – B.A. in Liberal Studies</li>
        </ul>
        <h5>Courses</h5>
        <ul>
          <li>Flatiron School – Software Engineering certificate</li>
          <li>HarvardX – CS50x Introduction to Computer Science</li>
          <li>Codecademy – Learn Python 3</li>
          <li>Zero to Mastery – Data Structures & Algorithms</li>
          <li>LinkedIn Learning – SQL Essential Training</li>
          <li>LinkedIn Learning – Discrete Mathematics</li>
          <li>LinkedIn Learning – TypeScript Essential Traning</li>
          <li>HubSpot – HubSpot SEO</li>
          <li>Certiport – Adobe Certified Associate in Photoshop</li>
        </ul>
      </p>
    </div>
  );
}

export default Education;
