import React from "react";

function Hobbies() {
  return (
    <div id="sb">
      <h3>Hobbies</h3>
      <ul>
        <li>Baking</li>
        <li>Writing poetry</li>
        <li>Knitting</li>
        <li>Playing switch</li>
        <li>Collecting vinyl records</li>
        <li>Arranging flowers</li>
      </ul>
      <p>
        Check out my&#160;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/ambthebaker/"
          style={{ color: "#2c2e32", textDecoration: "underline" }}
        >
          baking
        </a>
        &#160;and&#160;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/amberpoet/"
          style={{ color: "#2c2e32", textDecoration: "underline" }}
        >
          poetry
        </a>
        &#160;Instagram accounts!
      </p>
      <img
        alt="paris brest pastry"
        src="/images/pie2.jpg"
        style={{
          marginTop: 15,
          height: 300,
          borderRadius: "1rem",
          border: "1px #dcdee8 solid",
          marginRight: 10,
        }}
      ></img>
      <img
        alt="paris brest pastry"
        src="/images/pastry-paris-brest.png"
        style={{
          marginTop: 15,
          height: 300,
          borderRadius: "1rem",
          border: "1px #dcdee8 solid",
        }}
      ></img>
    </div>
  );
}

export default Hobbies;
