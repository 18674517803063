import React from "react";

function Accomplishments() {
  return (
    <div id="sb">
      <p>
        <h3 style={{ marginBottom: 8 }}>Experience</h3>
        <h5 style={{ marginBottom: 0, fontSize: 13 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dronedeploy.com/"
            style={{ textDecoration: "none", fontSize: "1.25rem" }}
          >
            DroneDeploy
          </a>
          &nbsp;&nbsp;(9/2023-Present)
        </h5>
        <p style={{ marginBottom: -4 }}>Software Engineer, Fullstack</p>
        <p style={{ marginBottom: 3 }}>
          <i>Angular, RxJS, TypeScript, Python</i>
        </p>
        <ul>
          <li>
            Resolved critical customer escalations and complex bugs, including
            subscription charge issues, security gaps, and map
            coordinates-related bugs.
          </li>
          <li>
            Regularly incorporated test coverage into work, catching bugs before
            they reached production.
          </li>
          <li>
            Maintained and documented legacy code, enhancing team efficiency and
            knowledge.
          </li>
          <li>
            Daily pair-programming with teammates to increase team proficiency
            and quicker implementations.
          </li>
        </ul>

        <h5 style={{ marginBottom: 0, fontSize: 13 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pocketgems.com/"
            style={{ textDecoration: "none", fontSize: "1.25rem" }}
          >
            Pocket Gems
          </a>
          &nbsp;&nbsp;(9/2019-9/2023)
        </h5>
        <p style={{ marginBottom: -4 }}>Lead Coder (Donacode)</p>
        <p style={{ marginBottom: 3 }}>
          <i>Game: Episode Interactive</i>
        </p>
        <ul>
          <li>
            Designed and developed a customer-facing feature that improves
            monetization by 25% per story.
          </li>
          <li>
            Ideated/led 8 coded game initiatives that increased monetization and
            decreased development production time by ~323 hours.
          </li>
          <li>
            Created 22 course materials for teaching new users how to code.
          </li>
          <li>
            Debug technical issues during production, during QA via Jira, and as
            the designated firefighter after release.
          </li>
          <li>
            Conduct research for and implement new development tools to support
            content creation for our platform’s creators.
          </li>
        </ul>
        <h5>Awards</h5>
        <ul>
          <li>
            SkillsUSA – Promotional Bulletin Board (State Division, 1st Place)
          </li>
          <li>SkillsUSA – Advertising Design (Regional Division, 2nd Place)</li>
        </ul>
      </p>
    </div>
  );
}

export default Accomplishments;
